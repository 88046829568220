import Vue from 'vue'
import App from './App.vue'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css';
import {router} from './router'
import store from './vuex/store'
import {whitePath} from "@/common/config";

import './assets/style.less'
import '@/components'
import './utils/filter' // global filter
import api from "./common/api";

import PrevueImage from 'prevue-image'

Vue.use(api);

Vue.use(Antd)

Vue.use(PrevueImage)

Vue.config.productionTip = false

let token = window.localStorage.getItem('token');
let wite = window.localStorage.getItem('whitePath');
if (!token || !wite) {
    window.localStorage.setItem('whitePath', whitePath.join(','));
}

if (process.env.NODE_ENV !== 'development') { // process是Node环境全部变量, 运行时根据敲击的命令不同, 脚手架会取环境变量给env添加属性和值
    console.log = function () {
    }
    console.error = function () {
    }
    console.dir = function () {
    }
}

// const router = initRouter()
new Vue({
    render: h => h(App),
    router,
    store,
}).$mount('#app')
