// 请求和相应拦截等配置
// * 200: 成功。
// * 201: 没有数据。
// * 202: 已经存在。
// * 401: 用户名密码错误，没有权限。
// * 403：未正常登录。
// * 404: 未发现资源。
// * 408: 超时。
// * 500：服务器错误。

import axios from "axios";
import {router} from "@/router";
// import store from "@/store";
// import Router from 'vue-router'
const baseURL = "https://uni-api.huixinghao.cn"; //正式接口
// const baseURL ="https://dev.bilibililink.com"; // 测试接口
// 创建 axios 实例
let http = axios.create({
    baseURL,
    timeout: 360000
});

// 设置 post 、put 默认 Content-Type
// http.defaults.headers.post["Content-Type"] = "application/json";
http.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded";

// 添加请求拦截器
http.interceptors.request.use(
    config => {
        if (window.localStorage.getItem('token') && window.localStorage.getItem('token') != undefined && window.localStorage.getItem('token') != 'undefined') {
            config.headers["token"] = window.localStorage.getItem('token');
            config.headers["Authorization"] = 'Bearer ' + window.localStorage.getItem('token');
        } else {
            config.headers["token"] = '';
        }
        let temp_uid = router.history.current.query.temp_uid
        console.log('axios', router.history.current.query)
        if (temp_uid) {
            config.params = {
                temp_uid,
                ...config.params
            }
            // config.params.temp_uid=temp_uid
        }


        // if (config.method === "post" || config.method === "put") {
        //   // post、put 提交时，将对象转为string
        //   config.data = JSON.stringify(config.data);
        // }
        return config;
    },
    error => {
        // 请求错误处理
        return Promise.reject(error);
    }
);

// 添加响应拦截器
http.interceptors.response.use(
    response => {
        let {data} = response;
        // if(data.code === 201) {
        // 	return
        // 	}

        if (data.code == 401 || data.code == 402) {
            // window.localStorage.removeItem('token')
            window.localStorage.clear()
            window.localStorage.setItem('whitePath', '/login,/login/qrcode,/login/account,/login/reg,/,/index/dashboard,/404')
            router.replace({
                path: '/login',
                query: {redirect: router.currentRoute.fullPath}
            })
        }

        if (data.code == 1001) {
            router.replace({
                path: '/403'
            })
            data.message = '暂无权限'
        }

        // if (data.code==402){
        // 	window.localStorage.removeItem('token')
        // 	window.localStorage.removeItem('userinfo')
        // 	store.commit("user/SET_LOGIN", false);
        // 	router.replace({
        // 		path: '/login',
        // 		query: {redirect: router.currentRoute.fullPath}
        // 	})
        // }
        return data;
    },
    error => {
        console.log('Network error', error)
        return error
        // let info = {};
        // let { status, statusText, data } = error.response;
        // if (!error.response) {
        //   info = {
        //     code: 5000,
        //     msg: "Network Error"
        //   };
        // } else {
        //   info = {
        //     code: status,
        //     data,
        //     msg: statusText
        //   };
        // }
        // return Promise.reject(info);
    }
);

/**
 * 创建统一封装过的 axios 实例
 * @return { AxiosInstance }
 */
function install(Vue) {
    Vue.prototype.$api = http;
}

export default {
    install,
    baseURL
};
