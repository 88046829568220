import Vue from 'vue'
import Router from 'vue-router'
import store from '../vuex/store'

Vue.use(Router)

const options = require('./config').default
// formatRoutes(options.routes)
options.mode='history'
let temp_uid=''

/**
 * 初始化路由实例
 * @param isAsync 是否异步路由模式
 * @returns {VueRouter}
 */
// function initRouter() {
//   const options = require('./config').default
//   // formatRoutes(options.routes)
//   options.mode='history'
  
//   return new Router(options)
// }

// 保存原来的push函数
const routerPush = Router.prototype.push 
const router = new Router(options)
router.beforeEach((to, from, next)=>{
  // console.log('to',  to)
  // console.log('store',store.state)


// 重写push函数
Router.prototype.push = function push(location) {
//  console.log('route',location)
//  console.log('Router',Router.prototype)
//  console.log('temp_uid',temp_uid)
//  console.log('to',  to)
 // 这个if语句在跳转相同路径的时候，在路径末尾添加新参数（一些随机数字）
 // 用来触发watch
 if(typeof(location)=="string"){
 var Separator = "&";
 if(location.indexOf('?')==-1) { Separator='?'; }
 if(location.indexOf('temp_uid')==-1) { 
  location = location + Separator + "temp_uid=" + temp_uid;
  location = location + "&random=" + Math.random();
 }else{
  location = location + Separator + "random=" + Math.random();
 }

//  location = location + Separator + "random=" + Math.random();
 }
 
 // 这个语句用来解决报错
 // 调用原来的push函数，并捕获异常
 return routerPush.call(this, location).catch(error => error)
}

// next()
//判断是否在白名单内
  let whitePath=window.localStorage.getItem('whitePath').split(',')
  // console.log('whitePath',to.path, whitePath)
  if(whitePath.includes(to.path)){
    if(from.query.temp_uid){
      temp_uid=from.query.temp_uid
      to.query.temp_uid=from.query.temp_uid
    }
    // path = from.path //path为定义的变量，不是vue的data定义的变量，当前生命周期data还未初始化
    next()
  }else{
    next({path: '/404'})
  }
})

export {router,temp_uid}
