<template>
    <div class="le-card-container " :style="{
      width,
      minHeight:height,
      background
    }"  @click="$emit('cardClick')"     >
    <slot></slot>
    </div>
</template>

<script>

export default {
  name: "le-card",
  components: {
  },
  data() {
    return {
    };
  },
  props:{
    width:{
      type:String,
      default:'500px'
    },
    height:{
      type:String,
      default:'100px'
    },
    background:{
      type:String,
      default:'#FFFFFF'
    }
  },
  computed: {
  },
  mounted(){

  },
  methods: {

  }
};
</script>

<style lang="less" scoped>
.le-card-container {
  box-shadow: 0px 0px 10px 1px rgba(205, 206, 210, 0.4);
  border-radius: 10px;
  position: relative;
}
</style>
