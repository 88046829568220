<template>
    <div class="le-button-container " :style="{
      width,
      height,
      fontSize,
      background,
      lineHeight:height
    }"  @click="clickBtn()" >

    <div  v-if="loading" ><a-icon type="loading" /></div>
    <div  v-else >{{txt}}</div>
    </div>
</template>

<script>

export default {
  name: "le-button",
  components: {
  },
  data() {
    return {
    };
  },
  props:{
    width:{
      type:String,
      default:'80px'
    },
    height:{
      type:String,
      default:'32px'
    },
    fontSize:{
      type:String,
      default:'16px'
    },
    txt:{
      type:String,
      default:'搜索'
    },
    loading:{
      type:Boolean,
      default:false
    },
    background:{
      type:String,
      default:'linear-gradient(-10deg, #69C6FF, #3E83FF)'
    }
  },
  computed: {
  },
  mounted(){

  },
  methods: {
    clickBtn(){
      if(this.loading){
        return false;
      }
      this.$emit('btnClick')
    }
  }
};
</script>

<style lang="less" scoped>
.le-button-container {
  // background: linear-gradient(-10deg, #69C6FF, #3E83FF);
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  color: #FFFFFF;
  user-select:none;
}
</style>
