
// 路由配置
const options = {
  routes: [
    {
      path: '/login',
      name: '登录页',
      component: () => import('@/pages/login'),
      redirect: '/login/qrcode',
      children: [
        {
          path: '/login/qrcode',
          name: '扫码登录注册',
          meta: {
            keepAlive: true
          },
          component: () => import('@/pages/login/Qrcode.vue'),
        },{
          path: '/login/account',
          name: '账号登录',
          meta: {
            keepAlive: true
          },
          component: () => import('@/pages/login/Account.vue'),
        },{
          path: '/login/reg',
          name: '账号密码注册',
          meta: {
            keepAlive: true
          },
          component: () => import('@/pages/login/Reg.vue'),
        }
      ]
    },
    {
      path: '*',
      name: '404',
      component: () => import('@/pages/exception/404'),
    },
    {
      path: '/',
      name: '工会后台',
      component: () => import('@/pages/index/Index.vue'),
      children: [
        {
          path: '/home/index',
          name: '首页',
          meta: {
            keepAlive: true,
            active: true,
          },
          component: () => import('@/pages/home/Home.vue'),
        },
        {
          path: '/403',
          name: '暂无权限',
          meta: {
            keepAlive: true,
            active: true,
            icon: `icon-gonghuixinxi`
          },
          component: () => import('@/pages/403/Index.vue'),
        },
        {
          path: '/index/dashboard',
          name: '团队管理',
          meta: {
            keepAlive: true,
            active: true,
            icon: `icon-gonghuixinxi`
          },
          component: () => import('@/pages/dashboard/Index.vue'),
        },
        {
          path: '/star',
          name: '星云系统',
          meta: {
            keepAlive: true,
            active: true,
            icon: `icon-a-xingyunxitong1`
          },
          redirect: '/star/order',
          component: () => import('@/pages/star/Index.vue'),
          children:[
            {
              path: '/star/order',
              name: '用户下单',
              component: () => import('@/pages/star/order/Index.vue'),
            },
            {
              path: '/star/send',
              name: '发放星光',
              component: () => import('@/pages/star/send/Index.vue'),
            },
            {
              path: '/star/sends',
              name: '星光发放',
              component: () => import('@/pages/star/sends/Index.vue'),
            },
            {
              path: '/star/guild',
              name: '我的公会',
              component: () => import('@/pages/star/guild/Index.vue'),
            },
            {
              path: '/star/bill',
              name: '自助下单',
              component:()=>import('@/pages/star/bill/Index.vue'),
            }
          ]
        },
        {
          path: '/auth',
          name: '权限管理',
          meta: {
            keepAlive: true,
            active: true,
            icon: `icon-a-xingyunxitong1`
          },
          redirect: '/auth/role',
          component: () => import('@/pages/auth/Index.vue'),
          children:[
            {
              path: '/auth/role',
              name: '角色管理',
              component: () => import('@/pages/auth/role/Index.vue'),
            },
            {
              path: '/auth/menu',
              name: '菜单管理',
              component: () => import('@/pages/auth/menu/Index.vue'),
            },
            {
              path: '/auth/guild',
              name: '公会管理',
              component: () => import('@/pages/auth/guild/Index.vue'),
            },
            {
              path: '/auth/config',
              name: '站点配置',
              component: () => import('@/pages/auth/config/Index.vue'),
            },
            {
              path: '/auth/account',
              name: '账号管理',
              component: () => import('@/pages/auth/account/Index.vue'),
            }
          ]
        },

        {
          path: '/anchor',
          name: '主播管理',
          meta: {
            keepAlive: true,
            active: true,
            icon: `icon-zhubochaxun`
          },
          component: () => import('@/pages/anchor/Index.vue')
        },

        {
          path: '/anchor-manager',
          name: '主播管理',
          meta: {
            keepAlive: true,
            active: true,
            icon: `icon-zhubochaxun`
          },
          component: () => import('@/pages/anchor/Manager.vue'),
          redirect: '/anchor-manager/list',
          children:[
            {
              path: '/anchor-manager/log',
              name: '入会记录',
              component: () => import('@/pages/anchor/log/Index.vue'),
            },
            {
              path: '/anchor-manager/list',
              name: '主播查询',
              component: () => import('@/pages/anchor/list/Index.vue'),
            },
            {
              path: '/anchor-manager/plain',
              name: '游戏素人主播',
              component: () => import('@/pages/anchor/plain/Index.vue'),
            },
            {
              path: '/anchor-manager/invent',
              name: '虚拟素人主播',
              component: () => import('@/pages/anchor/invent/Index.vue'),
            },
            {
              path: '/anchor-manager/analysis',
              name: '数据分析',
              component:()=>import('@/pages/anchor/analysis/Index.vue'),
            }
          ]
        },

        {
          path: '/manage-data',
          name: '经营数据',
          meta: {
            keepAlive: true,
            active: true,
            icon: `icon-renwuzhongxin`
          },
          component: () => import('@/pages/manage/Data.vue'),
        },
        {
          path: '/star-123',
          name: '星云推广',
          meta: {
            keepAlive: true,
            active: true,
            icon: `icon-renwuzhongxin`
          },
          component: () => import('@/pages/promote/Data.vue'),
        },
        {
          path: '/manage-task',
          name: '经营数据',
          meta: {
            keepAlive: true,
            active: true,
            icon: `icon-renwuzhongxin`
          },
          component: () => import('@/pages/manage/Affiliated.vue'),
        },

        {
          path: '/broker',
          name: '经纪人管理',
          meta: {
            keepAlive: true,
            active: true,
            icon: `icon-zhubochaxun`
          },
          component: () => import('@/pages/broker/Index.vue'),
          redirect: '/broker/kpi',
          children:[
            {
              path: '/broker/kpi',
              name: '绩效管理',
              component: () => import('@/pages/broker/kpi/Index.vue'),
            },
            {
              path: '/broker/auth',
              name: '账号权限',
              component: () => import('@/pages/broker/auth/Index.vue'),
            }
          ]
        },

        {
          path: '/support',
          name: '扶持管理',
          meta: {
            keepAlive: true,
            active: true,
            icon: `icon-renwuzhongxin`
          },
          component: () => import('@/pages/support/Index.vue'),
        },
        {
          path: '/other',
          name: '第三方账户管理',
          meta: {
            keepAlive: true,
            active: true,
            icon: `icon-renwuzhongxin`
          },
          component: () => import('@/pages/other/Index.vue'),
        },


      ]
    },
    {
      path: '/loginMoble',
      name: '登录页',
      component: () => import('@/pages/loginMoble'),
      redirect: '/loginMoble/qrcode',
      children: [
        {
          path: '/loginMoble/qrcode',
          name: '扫码登录注册',
          meta: {
            keepAlive: true
          },
          component: () => import('@/pages/loginMoble/Qrcode.vue'),
        },{
          path: '/loginMoble/account',
          name: '账号登录',
          meta: {
            keepAlive: true
          },
          component: () => import('@/pages/loginMoble/Account.vue'),
        },{
          path: '/loginMoble/reg',
          name: '账号密码注册',
          meta: {
            keepAlive: true
          },
          component: () => import('@/pages/loginMoble/Reg.vue'),
        }
      ]
    },
    {
      path: '/404',
      name: '404',
      component: () => import('@/pages/exception/404'),
    },
    {
      path: '/403',
      name: '403',
      component: () => import('@/pages/exception/404'),
    },
  ]
}

export default options
